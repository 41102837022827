@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html {
    background: #060606;
    /* background: #1d2023; */
    color: #ccd0d8;
    width: 100%;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* border: 1px solid red; */
}
.outliner{
    outline: 8px solid #84a4fc !important;
    outline-offset: 4px !important;
}
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(46, 46, 46);
}

::-webkit-scrollbar-track {
    width: 0 !important;
}

.hed::after {
    content: "";
    display: block;
    height: 2px;
    width: 70%;
    background: #84a4fc;
    /* background-color: rgb(234 179 8); */
}

.banner {
    background: radial-gradient(circle at bottom right, #1462f318, #060606 30%);
}


.service {
    background: radial-gradient(circle at top right, #1462f318, #060606 30%)
}
.sercard div {
    backdrop-filter: blur(1px);
    z-index: 1;
    transition: 0.3s ease-in-out all;
    background: linear-gradient(#00000093, #00000093);
}

.sercard div:hover {
    backdrop-filter: blur(3px);
}

.sercard:nth-child(1)::after {
    content: 'WEB DESIGN';
    background: url(https://images.pexels.com/photos/1229861/pexels-photo-1229861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}

.sercard:nth-child(2)::after {
    content: 'FRONTEND DEVELOPER';
    background: url(https://images.pexels.com/photos/669996/pexels-photo-669996.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}

.sercard:nth-child(3)::after {
    content: 'PHOTO EDITING';
    background: url(https://images.pexels.com/photos/274973/pexels-photo-274973.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}

.sercard:hover>p,
.sercard:hover>a {
    display: block;
}

.sercard::after {
    z-index: 0;
    padding: 5% 0;
    display: block;
    overflow: hidden;
    font-size: 80px;
    font-weight: 800;
    color: #ccd0d8e5;
    height: 100%;
    transition: 0.3s ease-in-out all;
}

.sercard:hover::after {
    transform: scale(1.05);
}

.sercard:hover .ico {
    top: 0px;
}

.sercard p,
.sercard a {
    visibility: hidden;
    opacity: 0;
    position: relative;
    top: 50px;
    transition: 0.4s ease-in-out all;
}

/* .sercard a{
    transition: 0.5s ease-in-out all;
} */
.sercard:hover p,
.sercard:hover a {
    opacity: 1;
    visibility: visible;
    top: 0%;
}

.sercard .ico {
    top: 63%;
    transition: 0.5s ease-in-out all;
    position: relative;

}

.percent svg circle:nth-child(2) {
    stroke: #1463f3;
    stroke-dasharray: 440;
    visibility: hidden;
    opacity: 0;
    stroke-dashoffset: calc(440 - (440 * var(--num)) / 100);
}

.num,
.num1 {
    visibility: hidden;
    opacity: 0;
}

.fadeIn {
    animation: fadeIn 1s linear forwards;
    animation-delay: 2s;
}

@keyframes fadeIn {
    0% {
        visibility: hidden;
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
}

.dot.anime {
    animation: animeDot 2s linear forwards;
}

@keyframes animeDot {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(calc(3.6deg * var(--num)));
    }
}

.dot::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 45%;
    transform: translateX(50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1463f3;
    box-shadow: 1px 1px 10px #1463f3,
        -1px -1px 10px #1463f3;
}

#pie {
    position: relative;
    left: -50px;
    visibility: hidden;
    opacity: 0;
    transition: 0.7s ease-in-out all;
}

#pie.fadeRight {
    visibility: visible;
    opacity: 1;
    left: 0;
}

#bar {
    position: relative;
    right: -50px;
    visibility: hidden;
    opacity: 0;
    transition: 0.7s ease-in-out all;
}

#bar.fadeLeft {
    visibility: visible;
    opacity: 1;
    right: 0;
}

/* #060606ef,#060606ef        000818f6 */
.contact {
    background: linear-gradient(45deg, #00050ff6, #060606f9), url(https://images.pexels.com/photos/4974915/pexels-photo-4974915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}

.input-field input,
.input-field textarea {
    color: #060606 !important;
}

.input-field span {
    color: #444;
    position: absolute;
    top: 4px;
    left: 13px;
    transition: 0.5s;
    pointer-events: none;
}

.input-field input:focus~span,
.input-field textarea:focus~span,
.input-field input:valid~span,
.input-field textarea:valid~span {
    font-size: 12px;
    color: #1463f3;
    font-weight: 500;
    transform: translate(-6px, -20px);

}

@media screen and (max-width:1100px) {
    #pie {
        left: 0;
        visibility: visible;
        opacity: 1;
    }

    #bar {
        right: 0;
        visibility: visible;
        opacity: 1;
    }
}

@media screen and (max-width:650px) {
    .sercard::after {
        font-size: 50px;
    }

    .ico {
        font-size: 30px !important;
    }
}