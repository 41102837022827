/* 
.link::after{
    content: '';
    display: block;
    left: 0%;
    bottom: 0;
    width: 0%;
    height: 2px;
    transition: 0.3s ease-in-out all;
    background-color: #ccd0d8;
}
.link:hover::after,
.link.active::after{
    width: 100%;
} */
.toggle.active .open,
.toggle .close{
    display: none;
}
.toggle .open,
.toggle.active .close{
    display: block;
}
@media screen and (max-width:767px) {
    .link::after{
        display: none;
    }
    #navlink{
        position: fixed;
        left: -100%;
        transition: 0.3s ease-in-out all;
    }
    #navlink.active{
        left: 0%;
    }
}