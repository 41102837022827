.hover:hover .overlay{
    backdrop-filter: blur(2px);
    background: linear-gradient(#06060688,#06060688);
}
.hover:hover .overlay a{
    /* top: 0; */
    opacity: 1;
    visibility: visible;
}
.overlay{
    position: absolute;
    top: 0%;
    background: linear-gradient(#0606065b,#0606065b);
    backdrop-filter: blur(1px);
    transition: 0.3s ease-in-out all;
}
.hover:hover .overlay p{
    top: 0;
}
.overlay p{
    transition: 0.2s ease-in-out all;
    position: relative;
    top: 30px;
}
.overlay a{
    /* transition: 0.2s ease-in-out all;
    position: relative;
    top: 30px; */
    visibility: hidden;
    opacity: 0;
}